export const Sizings = `
  --ca-ui-margin--xsmall: 1px;
  --ca-ui-margin--small: 4px;
  --ca-ui-margin--medium: 8px;
  --ca-ui-margin--large: 16px;
  --ca-ui-margin--xlarge: 24px;
  --ca-ui-margin--xxlarge: 32px;
  --ca-ui-padding--xsmall: 1px;
  --ca-ui-padding--small: 4px;
  --ca-ui-padding--medium: 8px;
  --ca-ui-padding--large: 16px;
  --ca-ui-padding--xlarge: 24px;
  --ca-ui-padding--xxlarge: 32px;
`;